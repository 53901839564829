import React, { Component } from 'react';
import base from '../components/rebase';
import {
  convertToSlug,
  convertToArray,
  imageVerification,
  convertMonth
} from '../CommonFunctions';
//import { withRouter } from 'react-router-dom';
// import ScrollUpButton from 'react-scroll-up-button';

import Loading from '../components/UI/Loading';
import TopBar from '../components/UI/TopBar';
import FloatingButton from '../components/UI/FloatingButton';

import CardTrip from '../components/Cards/CardTrip';
import CardAlert from '../components/Cards/CardAlert';
import NewTripForm from '../components/Forms/NewTripForm';
import 'normalize.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.convertToSlug = convertToSlug;
    this.convertToArray = convertToArray;
    this.imageVerification = imageVerification;
    this.convertMonth = convertMonth;

    this.loadTrips = () => {
      base
        .get('trips', {
          context: this,
          //query: (ref)=> ref.where('owner','==',localStorage.getItem("email")),
          query: ref => {
            return (
              ref
                // .where('owner', '==', localStorage.getItem('email'))
                //.orderBy('startDate')
                .where(
                  'travellers',
                  'array-contains',
                  localStorage.getItem('email')
                )
              //Falta ordenar por fecha de inicio
            );
          }
        })
        .then(data => {
          console.log(data);
          this.setState({
            data: data,
            loading: false
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            noTripMsg: true
          });
        });
    };
    // this.displayNewTripForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewTripForm = true;
    //   this.setState(newState);
    // };
    // this.hideNewTripForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewTripForm = false;
    //   this.setState(newState);
    // };
    this.saveNewTrip = (tripName, travellers, startDate, imageURL) => {
      if (!tripName || !travellers || !startDate || !imageURL) {
        alert('Por favor proporcione todos los datos solicitados');
        return false;
      }
      let anio = parseInt(startDate.substring(0, 4));
      let mes = parseInt(startDate.substring(5, 7));
      let dia = parseInt(startDate.substring(8, 10));
      let mesText = this.convertMonth(mes);
      let newTrip = base
        .addToCollection(
          'trips',
          {
            tripName: tripName,
            slug:
              this.convertToSlug(startDate) +
              '-' +
              this.convertToSlug(tripName),
            travellers: this.convertToArray(travellers),
            startDate: startDate,
            owner: localStorage.getItem('email'),
            uid: localStorage.getItem('uid'),
            imageURL: this.imageVerification(imageURL),
            activitydatetext: dia + ' de ' + mesText + ' del ' + anio
          },
          this.convertToSlug(startDate) + '-' + this.convertToSlug(tripName)
        )
        .then(() => {})
        .catch(err => {});
      //available immediately, you don't have to wait for the callback to be called
      let generatedKey = newTrip.key;
      if (generatedKey !== '') this.loadTrips();
      this.setState({ noTripMsg: false });
      // alert('newTrip: ' + JSON.stringify(newTrip));
      return true;
    };
    this.state = {
      data: [],
      showNewTripForm: true,
      loading: false,
      noTripMsg: false
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.loadTrips();
  }

  render() {
    let showLoading = <Loading />;
    let showCards = this.state.data.map(trip => {
      return <CardTrip trip={trip} key={'trip-' + trip.slug} />;
    });
    return (
      <div>
        {this.state.loading && showLoading}
        <TopBar />
        <div>
          {this.state.showNewTripForm && (
            <NewTripForm
              saveNewTrip={this.saveNewTrip}
              hideNewTripForm={this.hideNewTripForm}
            />
          )}
          <section className="cardList">
            <div id="timeline" />
            <div className="SectionTitle"> Viajes </div>
            {this.state.noTripMsg && (
              <CardAlert
                title="Aún no tienes viajes registrados."
                subtitle="¡Comienza ahora!"
              />
            )}
            {!this.state.loading && showCards}
          </section>

          {/* <button
            className="btn-floating btn-large waves-effect waves-light red"
            onClick={this.displayNewTripForm}
          >
            <i className="material-icons">add</i>
          </button> */}
          {/* <ScrollUpButton
            ContainerClassName="btn-floating btn-large waves-effect waves-light red botontop"
            TransitionClassName="botontransition"
          /> */}

          <FloatingButton
          // displayNew={this.displayNewTripForm}
          />
        </div>
      </div>
    );
  }
}
export default Dashboard;
