import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import { datePickerOptions, modalOptions } from '../../CommonFunctions';

class NewTripForm extends Component {
  constructor(props) {
    super(props);
    this.txtTripName = React.createRef();
    this.txtTravellers = React.createRef();
    this.txtStartDate = React.createRef();
    this.txtImageURL = React.createRef();
    this.modalOptions = modalOptions;
    this.datePickerOptions = datePickerOptions;
  }
  componentDidMount() {
    let elems = document.querySelectorAll('.datepicker');
    let instanceDatepicker = M.Datepicker.init(elems, this.datePickerOptions);
    elems = document.querySelectorAll('.modal');
    let instancesModal = M.Modal.init(elems, this.modalOptions);
    console.log(instanceDatepicker + instancesModal);
    this.modal = M.Modal.getInstance(document.querySelector('#new'));
  }
  render() {
    return (
      <section
        key="newTripForm"
        className="newTripForm modal card grey lighten-4"
        id="new"
      >
        <div className="">
          <div className="card-content grey-text">
            <span className="card-title">Nuevo Viaje</span>
            <div className="row">
              <div className="input-field col s12">
                <i className="material-icons prefix">card_travel</i>
                <input
                  id="txtTripName"
                  type="text"
                  className="validate"
                  ref={this.txtTripName}
                />
                <label className="grey-text" htmlFor="txtTripName">
                  Nombre del viaje
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">group</i>
                <input
                  id="txtTravellers"
                  type="text"
                  className="validate"
                  ref={this.txtTravellers}
                />
                <label className="grey-text" htmlFor="txtTravellers">
                  Viajeros (Emails separados por coma)
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">date_range</i>
                <input
                  id="txtStartDate"
                  type="text"
                  className="datepicker"
                  ref={this.txtStartDate}
                />
                <label className="grey-text" htmlFor="txtStartDate">
                  Fecha de inicio del viaje
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">image</i>
                <input
                  id="txtImageURL"
                  type="text"
                  className="text"
                  ref={this.txtImageURL}
                />
                <label className="grey-text" htmlFor="txtImageURL">
                  URL de la imagen
                </label>
              </div>
              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large red modal-close"
                  //onClick={this.props.hideNewTripForm}
                >
                  <i className="material-icons center">close</i>
                </button>
              </div>
              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large cyan"
                  onClick={() => {
                    if (
                      this.props.saveNewTrip(
                        this.txtTripName.current.value,
                        this.txtTravellers.current.value,
                        this.txtStartDate.current.value,
                        this.txtImageURL.current.value
                      )
                    ) {
                      // console.log("VALOR DE MODAL: ");
                      this.modal.close();
                      this.txtTripName.current.value = '';
                      this.txtTravellers.current.value = '';
                      this.txtStartDate.current.value = '';
                      this.txtImageURL.current.value = '';
                    }
                    //this.props.hideNewTripForm();
                  }}
                >
                  <i className="material-icons center">save</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NewTripForm;
