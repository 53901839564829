import React, { Component } from 'react';
import './styles/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LoginFirebase from './routes/LoginFirebase';
import { app as firebaseApp } from './components/rebase';
import TripActivities from './routes/TripActivities';
import Dashboard from './routes/Dashboard';
import Notes from './routes/Notes';
import Relevant from './routes/Relevant';
import Page404 from './routes/Page404';

export const firebaseAppAuth = firebaseApp.auth();

const UserData = React.createContext();
export const UserConsumer = UserData.Consumer;

class App extends Component {
  constructor(props) {
    super(props);

    this.toggleName = user => {
      console.log('Entrando a toggleName');
    };

    this.setActiveUser = activeUser => {
      console.log('Ejecutando setActiveUser...');
      let newState = { ...this.state };
      newState.isAuthenticated = true;
      newState.activeUser.uid = activeUser.uid;
      newState.activeUser.displayName = activeUser.displayName;
      newState.activeUser.photoURL = activeUser.photoURL;
      newState.activeUser.email = activeUser.email;
      newState.activeUser.emailVerified = activeUser.emailVerified;
      newState.activeUser.lastLoginAt = activeUser.lastLoginAt;
      newState.activeUser.createdAt = activeUser.createdAt;

      this.setState(newState);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('displayName', activeUser.displayName);
      localStorage.setItem('photoURL', activeUser.photoURL);
      localStorage.setItem('email', activeUser.email);
      localStorage.setItem('uid', activeUser.uid);
      localStorage.setItem('lastLoginAt', activeUser.lastLoginAt);
      localStorage.setItem('createdAt', activeUser.createdAt);
    };

    this.removeActiveUser = sidebar => {
      //const {signOut} = this.props;
      sidebar.close();
      let newState = { ...this.state };
      newState.isAuthenticated = false;
      newState.activeUser.uid = '';
      newState.activeUser.displayName = '';
      newState.activeUser.photoURL = '';
      newState.activeUser.email = '';
      newState.activeUser.emailVerified = '';
      newState.activeUser.lastLoginAt = '';
      newState.activeUser.createdAt = '';
      this.setState(newState);

      localStorage.setItem('isAuthenticated', false);
      localStorage.setItem('displayName', '');
      localStorage.setItem('photoURL', '');
      localStorage.setItem('email', '');
      localStorage.setItem('uid', '');
      localStorage.setItem('lastLoginAt', '');
      localStorage.setItem('createdAt', '');

      firebaseAppAuth.signOut();

      console.log(
        'Los valores del usuario activo se han eliminado satisfactoriamente. isAuthenticated in localstorage vale: ' +
          localStorage.getItem('isAuthenticated')
      );
    };

    this.state = {
      isAuthenticated: false,
      activeUser: {
        uid: '',
        displayName: '',
        photoURL: '',
        email: '',
        emailVerified: '',
        lastLoginAt: '',
        createdAt: ''
      },
      removeActiveUser: this.removeActiveUser,
      setActiveUser: this.setActiveUser,
      toggleName: this.toggleName
    };
  }

  componentDidUpdate() {
    //console.log("La sesión está: "+localStorage.getItem('isAuthenticated'));
  }

  render() {
    // console.log("Desde render: Sesión localstorage - "+localStorage.getItem('isAuthenticated'));
    // console.log("Desde render: Sesión state - "+this.state.isAuthenticated);
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('isAuthenticated') === 'true' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
    );
    return (
      //El orden de las rutas afecta un chingo el producto
      <UserData.Provider value={this.state}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LoginFirebase} />
            <PrivateRoute path="/dashboard/:trip" component={TripActivities} />
            <PrivateRoute path="/dashboard/" component={Dashboard} />
            <PrivateRoute path="/notes" component={Notes} />
            <PrivateRoute path="/relevant" component={Relevant} />
            <Route component={Page404} />
          </Switch>
        </BrowserRouter>
      </UserData.Provider>
    );
  }
}
export default App;
//export default App;
