import React from 'react';

function DateTitle(props) {
  return (
    <div className="DateTitle">
      <strong>{props.activity.activitydia}</strong>
      <span>{props.activity.activitymestext}</span>
    </div>
  );
}

export default DateTitle;
