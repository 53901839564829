export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function convertToArray(Text) {
  Text = localStorage.getItem('email') + ',' + Text;
  Text = Text.replace(/\s/g, '');
  let newArray = Text.split(',');
  return newArray;
}

export function imageVerification(img) {
  let imgTest = img.toLowerCase();
  if (
    (imgTest.startsWith('http://') || imgTest.startsWith('https://')) &&
    (imgTest.endsWith('.jpg') ||
      imgTest.endsWith('.jpeg') ||
      imgTest.endsWith('.png') ||
      imgTest.endsWith('.gif'))
  )
    return img;
  else return '/images/00.jpg';
}

export function convertMonth(mes) {
  switch (mes) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return mes;
  }
}

export function convert12hrs(timeString) {
  let H = +timeString.substr(0, 2);
  let h = H % 12 || 12;
  let ampm = H < 12 ? ' AM' : ' PM';
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

export function convert24hrs(time) {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === 'PM' && hours < 12) hours = hours + 12;
  if (AMPM === 'AM' && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  return sHours + ':' + sMinutes;
}

//Common elements configuration

export let datePickerOptions = {
  format: 'yyyy-mm-dd',
  autoClose: true,
  i18n: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    weekdaysAbbrev: ['D', 'L', 'M', 'Mi', 'J', 'V', 'S']
  }
};

export let timePickerOptions = {
  autoClose: true,
  twelveHour: true
};

export let modalOptions = {
  opacity: 0.5,
  startingTop: '1%',
  endingTop: '2%'
};
