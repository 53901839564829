import React, { Component } from 'react';
import withFirebaseAuth from 'react-with-firebase-auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { app as firebaseApp } from '../components/rebase';
import { UserConsumer } from '../App';
import { Redirect, withRouter } from 'react-router-dom';

class LoginFirebase extends Component {
  loggedIn = user => {
    Authentication.isAuthenticated = true;
    Authentication.activeUser = user;
    //setActiveUser(user);
    //return <div>Hola {user.displayName}</div>;
    //return <Redirect to={'/card-list'} />;
  };

  render() {
    const { user, signOut, signInWithGoogle } = this.props;
    Authentication.signOut = signOut;
    return (
      <UserConsumer>
        {({ activeUser, setActiveUser }) => (
          <section key="login" className="login">
            <h1>
              <strong>•</strong> <span>Travify</span>
            </h1>
            <p className="welcome">
              ¡Bienvenido a <strong>Travify</strong> su organizador de
              vacaciones por excelencia!
            </p>
            <div className="row loginform">
              {user ? (
                localStorage.getItem('isAuthenticated') === 'true' ? (
                  <Redirect to={'/dashboard'} />
                ) : (
                  setActiveUser(user)
                )
              ) : (
                <button
                  onClick={signInWithGoogle}
                  className="waves-effect waves-light btn-large cyan"
                >
                  <i className="material-icons left">vpn_key</i>Google Login
                </button>
              )}
            </div>
            <footer>&copy; Travify 2019</footer>
          </section>
        )}
      </UserConsumer>
    );
  }
}

export const firebaseAppAuth = firebaseApp.auth();
const providers = { googleProvider: new firebase.auth.GoogleAuthProvider() };

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(withRouter(LoginFirebase));

export let Authentication = {
  isAuthenticated: false,
  activeUser: [],
  signOut: []
};
