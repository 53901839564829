import React from 'react';

function Loading(props) {
  return (
    <article className="splash">
      <div>
        <img
          src="https://data.whicdn.com/images/264942376/original.gif"
          alt="Cargando..."
        />
        <h1>Cargando información del viaje...</h1>
      </div>
    </article>
  );
}

export default Loading;
