import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/css/materialize.min.css';
import { UserConsumer } from '../../App';
import { Link } from 'react-router-dom';

export class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: ''
    };
  }
  redirectToTarget = target => {
    this.context.router.history.push(target);
  };

  componentDidMount() {
    let newState = { ...this.state };
    let elem = document.querySelector('.sidenav');
    newState.instance = M.Sidenav.init(elem, {
      edge: 'left',
      inDuration: 250,
      isFixed: true
    });
    this.setState(newState);
  }

  render() {
    return (
      <UserConsumer>
        {({ activeUser, setActiveUser, removeActiveUser }) => (
          <div>
            <div className="top-bar-space" />
            <div className="top-bar">
              <div id="top-bar-logo">
                <strong>•</strong> <span>Travify</span>
              </div>
            </div>
            <div id="sideNavButton">
              <ul id="slide-out" className="sidenav">
                <li />
                <li>
                  <div className="user-view">
                    <div className="background">
                      {/* <img
                        src="https://us.123rf.com/450wm/helenlane/helenlane1610/helenlane161000693/63602514-asian-japanese-tradition-seamless-pattern-in-red-colors-u043e-u0442-black-background-.jpg?ver=6"
                        alt={localStorage.getItem('displayName')}
                      /> */}
                    </div>
                    <a href="#user">
                      <img
                        className="circle"
                        src={localStorage.getItem('photoURL')}
                        alt={localStorage.getItem('displayName')}
                      />
                    </a>
                    <a href="#name">
                      <span className="white-text name">
                        {localStorage.getItem('displayName')}
                      </span>
                    </a>
                    <a href="#email">
                      <span className="white-text email">
                        {localStorage.getItem('email')}
                      </span>
                    </a>
                  </div>
                </li>
                <li>
                  <Link to={'/dashboard'} className="sidenav-close">
                    <i className="material-icons">card_travel</i>Viajes
                  </Link>
                </li>
                <li>
                  <Link to={'/notes'} className="sidenav-close">
                    <i className="material-icons">list</i>Notas y Recordatorios
                  </Link>
                </li>
                <li>
                  <Link to={'/relevant'} className="sidenav-close">
                    <i className="material-icons">bookmarks</i>Información
                    relevante
                  </Link>
                </li>
                <li>
                  <Link
                    className="sidenav-close"
                    to={'#Cerrar-Sesion'}
                    onClick={() => {
                      removeActiveUser(this.state.instance);
                    }}
                  >
                    <i className="material-icons">power_settings_new</i>Cerrar
                    sesión
                  </Link>
                </li>
              </ul>
              <div data-target="slide-out" className="sidenav-trigger">
                <i className="material-icons">menu</i>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export default TopBar;
