import React, { Component } from 'react';

class CardRelevant extends Component {
  // constructor(props){
  //   super(props);
  //   this.goToEvents=(slug)=>{
  //     alert("El slug es: "+slug);
  //   }
  // }
  render() {
    return (
      <div>
        <article className={'card card-note card-relevant'}>
          <div className="card-image imgFullWidth waves-effect waves-block waves-light">
            <div className="card-note-tag">{this.props.relevant.tripname}</div>
            <img
              alt={this.props.relevant.tripname}
              src={this.props.relevant.tripimage}
            />
          </div>
          <div className="card-line-top" />
          <div className="card-content bg-card">
            <h4>
              <i className="tiny material-icons">note</i>
              {this.props.relevant.relevanttitle}
            </h4>
            <p className="card-description">
              {this.props.relevant.relevanttext}
            </p>
            {this.props.relevant.relevanturl && (
              <p className="alignButton">
                <a
                  className="waves-effect waves-light btn cyan"
                  href={this.props.relevant.relevanturl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="material-icons left">link</i>Enlace
                </a>
              </p>
            )}
          </div>
        </article>
      </div>
    );
  }
}
export default CardRelevant;
