import React, { Component } from 'react';
import base from '../components/rebase';
import { convertToSlug, imageVerification } from '../CommonFunctions';
//import { withRouter } from 'react-router-dom';
//import ScrollUpButton from 'react-scroll-up-button';

import Loading from '../components/UI/Loading';
import TopBar from '../components/UI/TopBar';
import FloatingButton from '../components/UI/FloatingButton';

import CardRelevant from '../components/Cards/CardRelevant';
import CardAlert from '../components/Cards/CardAlert';
import NewRelevantForm from '../components/Forms/NewRelevantForm';
import M from 'materialize-css/dist/js/materialize.min.js';

class Relevant extends Component {
  constructor(props) {
    super(props);
    this.convertToSlug = convertToSlug;
    this.imageVerification = imageVerification;
    this.loadRelevant = () => {
      this.setState({ loading: true });
      base
        .get('trips', {
          context: this,
          //query: (ref)=> ref.where('owner','==',localStorage.getItem("email")),
          query: ref => {
            return (
              ref
                //.where('owner', '==', localStorage.getItem('email'))
                .where(
                  'travellers',
                  'array-contains',
                  localStorage.getItem('email')
                )
            );
          }
        })
        .then(data => {
          let slugs = [];
          data.map(trip => {
            return slugs.push({
              tripslug: trip.slug,
              tripimage: trip.imageURL,
              tripname: trip.tripName
            });
          });
          let newState = { ...this.state };
          newState.slugs = slugs;
          this.setState(newState);
          //console.log("Slugs: "+JSON.stringify(slugs));
          //Iniciar carga de notas
          this.setState({ relevants: [] });
          slugs.map(trip => {
            return base
              .get('trips/' + trip.tripslug + '/relevant', {
                context: this,
                query: ref => {
                  return ref.orderBy('relevantdatetime');
                }
              })
              .then(data => {
                //console.log('Durante el ciclo: ' + JSON.stringify(data));
                let newState = { ...this.state };
                for (const val in data) {
                  newState.relevants.push({
                    relevanttitle: data[val].relevanttitle,
                    relevanttext: data[val].relevanttext,
                    relevantdatetime: data[val].relevantdatetime,
                    relevanturl: data[val].relevanturl,
                    tripname: trip.tripname,
                    tripimage: this.imageVerification(trip.tripimage),
                    tripslug: trip.tripslug
                  });
                }
                // console.log("Valor de newState antes de meter en setState: "+JSON.stringify(newState));
                newState.loading = false;
                newState.noTripMsg = false;
                newState.noRelevant = false;
                this.setState(newState);
                // console.log(
                //   'Contenido final de state: ' + JSON.stringify(this.state)
                // );
              })
              .catch(err => {
                this.setState({
                  loading: false,
                  noRelevant: true
                });
              });
          });
        })
        .catch(err => {
          console.log('Error regresado: ' + err);
          this.setState({
            loading: false,
            noTripMsg: true
          });
        });
    };

    // this.displayNewRelevantForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewRelevantForm = true;
    //   this.setState(newState);
    // };
    // this.hideNewRelevantForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewRelevantForm = false;
    //   this.setState(newState);
    // };
    this.saveNewRelevant = (
      tripSlug,
      relevantTitle,
      relevantText,
      relevantURL
    ) => {
      if (!tripSlug || !relevantTitle || !relevantText) {
        alert('Por favor proporcione todos los datos solicitados');
        return false;
      }
      console.log(
        tripSlug + ' ' + relevantTitle + ' ' + relevantText + ' ' + relevantURL
      );
      let today = new Date();
      let date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      let newRelevant = base
        .addToCollection(
          'trips/' + tripSlug + '/relevant',
          {
            relevanttitle: relevantTitle,
            relevantdatetime: date,
            relevanttext: relevantText,
            relevanturl: relevantURL,
            relevantslug:
              this.convertToSlug(date) + this.convertToSlug(relevantTitle)
          },
          this.convertToSlug(date) + this.convertToSlug(relevantTitle)
        )
        .then(() => {})
        .catch(err => {});
      //available immediately, you don't have to wait for the callback to be called
      let generatedKey = newRelevant.key;
      if (generatedKey !== '') this.loadRelevant();
      this.setState({ noTripMsg: false });
      return true;
    };
    this.state = {
      relevants: [],
      slugs: [],
      showNewRelevantForm: true,
      loading: false,
      noTripMsg: false,
      noRelevant: false
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    let elems = document.querySelectorAll('.fixed-action-btn');
    let instance = M.FloatingActionButton.init(elems, {
      direction: 'left'
    });
    console.log(instance);
    this.loadRelevant();
  }

  render() {
    let showLoading = <Loading />;
    let showCards = this.state.relevants.map(relevant => {
      return (
        <div key={'cont' + relevant.key}>
          <CardRelevant relevant={relevant} key={'relevant-' + relevant} />
        </div>
      );
    });
    return (
      <div>
        {this.state.loading && showLoading}
        <TopBar />
        <div>
          {this.state.showNewRelevantForm && (
            <NewRelevantForm
              saveNewRelevant={this.saveNewRelevant}
              hideNewRelevantForm={this.hideNewRelevantForm}
              slugs={this.state.slugs}
            />
          )}
          <section className="cardList">
            <div id="timeline" />
            <div className="SectionTitle"> Relevante </div>
            {this.state.noTripMsg && (
              <CardAlert
                title="Aún no tienes viajes registrados"
                subtitle="Para agregar información relevante, primero debes tener viajes registrados."
              />
            )}
            {this.state.noRelevant && (
              <CardAlert
                title="Aún no tienes información relevante registrada"
                subtitle="Comienza en este momento."
              />
            )}
            {!this.state.loading && showCards}
          </section>
          {!this.state.noTripMsg && <FloatingButton />}
        </div>
      </div>
    );
  }
}
export default Relevant;
