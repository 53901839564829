import React, { Component } from 'react';

class ConfirmationAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div
        id="ConfirmationAlert"
        className="ConfirmationAlert modal card grey lighten-4"
      >
        <div className="modal-content">
          <div className="row">
            <h2 className="card-title grey-text text-darken-4">
              {this.props.alertTitle}
            </h2>
            <p>{this.props.alertContent}</p>
            <div className="input-field col s6 center">
              <button
                className="waves-effect waves-light btn-large red modal-close"
                //onClick={this.props.hideNewActivityForm}
              >
                <i className="material-icons center">close</i>
              </button>
            </div>
            <div className="input-field col s6 center">
              <button
                className="waves-effect waves-light btn-large cyan"
                onClick={() => {
                  if (this.props.alertFunctionParameter === '')
                    this.props.alertAction();
                  else
                    this.props.alertAction(this.props.alertFunctionParameter);
                }}
              >
                <i className="material-icons center">delete</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConfirmationAlert;
