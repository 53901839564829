import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CardTrip extends Component {
  // constructor(props){
  //   super(props);
  //   this.goToEvents=(slug)=>{
  //     alert("El slug es: "+slug);
  //   }
  // }
  render() {
    let viajeros = this.props.trip.travellers.map(viajero => {
      return (
        <li key={'li' + viajero}>
          <a href={'mailto:' + viajero}>
            <i className="tiny material-icons">account_circle</i> {viajero}
          </a>
        </li>
      );
    });
    return (
      <div>
        <article className={'card card-trip'}>
          <div className="card-image imgFullWidth waves-effect waves-block waves-light">
            <img
              alt={this.props.trip.tripName}
              src={this.props.trip.imageURL}
            />
            <span className="card-title">{this.props.trip.tripName}</span>
          </div>
          <div className="card-line-top card-line-bg-1" />
          <div className="card-content bg-card">
            <Link to={'/dashboard/' + this.props.trip.slug}>
              <button
                id="readMore"
                className="btn-floating btn-large waves-effect waves-light red activator card-line-bg-red"
                // onClick={()=>{this.goToEvents(this.props.trip.slug)}}
              >
                <i className="material-icons">open_in_new</i>
              </button>
            </Link>
            <ul>
              <li>
                <strong>Fecha de inicio:</strong>{' '}
                {this.props.trip.activitydatetext}
              </li>
              <li>
                {' '}
                <strong>Viajeros:</strong>
              </li>
              {viajeros}
            </ul>
          </div>
        </article>
      </div>
    );
  }
}
export default CardTrip;
