import React from 'react';
import base from '../components/rebase';
import {
  convertToSlug,
  imageVerification,
  convertMonth,
  convert12hrs,
  convert24hrs,
  modalOptions
} from '../CommonFunctions';
// import ScrollUpButton from 'react-scroll-up-button';

import Loading from '../components/UI/Loading';
import TopBar from '../components/UI/TopBar';
import FloatingButton from '../components/UI/FloatingButton';
import DateTitle from '../components/UI/DateTitle';
import ConfirmationAlert from '../components/UI/ConfirmationAlert';
import CardAlert from '../components/Cards/CardAlert';
import CardActivity from '../components/Cards/CardActivity';
import NewActivityForm from '../components/Forms/NewActivityForm';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'normalize.css';

// import 'materialize-css';
//import PropTypes from 'prop-types';
//import activities from './activities.json';
//import activities from 'http://localhost:81/minibackend/';

class TripActivities extends React.Component {
  //Variables de la clase.

  constructor(props) {
    super(props);
    this.convertToSlug = convertToSlug;
    this.imageVerification = imageVerification;
    this.convert24hrs = convert24hrs;
    this.convert12hrs = convert12hrs;
    this.convertMonth = convertMonth;

    this.loadActivities = showPastActivities => {
      this.setState({
        loading: true,
        noActivityMsg: false,
        showPastActivities: showPastActivities
      });
      let today = new Date();
      today =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      //today = '2019-11-18';
      base
        .get('trips/' + this.props.match.params.trip + '/activities', {
          context: this,
          //query: (ref)=> ref.where('slug','==', this.props.match.params.trip),
          query: ref => {
            if (!showPastActivities) {
              return ref
                .where('activitydatetime', '>', today)
                .orderBy('activitydatetime', 'asc');
            } else {
              return ref.orderBy('activitydatetime', 'asc');
            }
          }
        })
        .then(activities => {
          console.log('Activities recibido: ' + JSON.stringify(activities));
          this.setState({
            data: activities,
            loading: false,
            tripKey: this.props.match.params.trip
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            noActivityMsg: true,
            data: []
          });
        });
    };
    // this.displayNewActivityForm = () => {
    //   let newState = { ...this.state };
    //   newState.showNewActivityForm = true;
    //   this.setState(newState);
    // };
    // this.hideNewActivityForm = () => {
    //   let newState = { ...this.state };
    //   newState.showNewActivityForm = false;
    //   this.setState(newState);
    // };
    this.displayAllActivities = () => {
      this.loadActivities(true);
    };
    this.hideAllActivities = () => {
      this.loadActivities();
    };
    this.saveNewActivity = (
      activityTitle,
      startDate,
      time,
      activityType,
      notes,
      imageURL,
      mapURL,
      URL
    ) => {
      if (!activityTitle || !startDate || !time || !activityType || !notes) {
        alert('Por favor proporcione todos los datos solicitados');
        return false;
      }
      time = this.convert24hrs(time);
      let anio = parseInt(startDate.substring(0, 4));
      let mes = parseInt(startDate.substring(5, 7));
      let dia = parseInt(startDate.substring(8, 10));
      let mesText = this.convertMonth(mes);
      //alert('trips/' + this.props.match.params.trip + '/activities');
      let newActivity = base
        .addToCollection(
          'trips/' + this.props.match.params.trip + '/activities',
          {
            activityanio: anio,
            activitydatetext: dia + ' de ' + mesText + ' del ' + anio,
            activitydatetime: startDate + ' ' + time,
            activitydia: dia,
            activityhora: time,
            activityimage: this.imageVerification(imageURL),
            activitylocation: mapURL,
            activitymes: mes,
            activitymestext: mesText.substring(0, 3),
            activitynotes: notes,
            activitytitle: activityTitle,
            activitytype: activityType,
            activityurl: URL,
            activityid:
              this.convertToSlug(startDate) +
              '-' +
              this.convertToSlug(time) +
              '-' +
              this.convertToSlug(activityTitle)
          },
          this.convertToSlug(startDate) +
            '-' +
            this.convertToSlug(time) +
            '-' +
            this.convertToSlug(activityTitle)
        )
        .then(() => {})
        .catch(err => {});
      let generatedKey = newActivity.key;
      if (generatedKey !== '') this.loadActivities();
      this.setState({ noActivityMsg: false });
      return true;
    };
    this.deleteModal = (activityTitle, activityid) => {
      this.setState({
        alertTitle: '¿Borrar Actividad?',
        alertContent: 'Deseas borrar la actividad: ' + activityTitle,
        alertActivityid: activityid
      });
      this.modal.open();
    };
    this.deleteActivity = activityid => {
      //alert('Borrando: trips/' + this.props.match.params.trip + '/activities/' + activityid);
      base
        .removeDoc(
          'trips/' + this.props.match.params.trip + '/activities/' + activityid
        )
        .then(() => {
          //document is deleted
          this.displayAllActivities(this.state.showPastActivities);
        })
        .catch(err => {
          //handle error
        });
      this.modal.close();
    };

    this.state = {
      data: [],
      tripKey: this.props.match.params.trip,
      loading: false,
      showNewActivityForm: true,
      noActivityMsg: false,
      alertTitle: '',
      alertContent: '',
      alertActivityid: '',
      showPastActivities: false,
      deleteActivity: this.deleteActivity
    };
  }

  //Función predefinida: Se montó el componente?
  componentDidMount() {
    this.setState({ loading: true });

    let elems = document.querySelectorAll('.alerta');
    let instancesModal = M.Modal.init(elems, modalOptions);
    this.modal = M.Modal.getInstance(
      document.querySelector('#ConfirmationAlert')
    );

    //setTimeout(() => {
    // Old mini API call
    // fetch('https://backend.travify.japonea.me/index.v1.php')
    //   .then(data => data.json())
    //   .then(data => this.setState({ data, loading: false }));
    this.loadActivities();
    //}, 5000);
  }

  //Función predefinida: Se actualizó el componente?
  // componentDidUpdate() {
  // 	console.log("El componente acaba de cambiar")
  // }

  //Función predefinida: Renderizar el componente

  render() {
    let showLoading = <Loading />;
    let currentdate, printdate;
    let showCards = this.state.data.map(activity => {
      if (currentdate !== activity.activitydatetext) {
        currentdate = activity.activitydatetext;
        printdate = true;
      } else {
        printdate = false;
      }

      return (
        <div key={'cont' + currentdate + activity.activityid}>
          {printdate && (
            <DateTitle
              activity={activity}
              currentdate={currentdate}
              key={'datetitle-' + currentdate}
            />
          )}
          <CardActivity
            activity={activity}
            key={'activity-' + activity.activityid}
            convert12hrs={this.convert12hrs}
            deleteModal={this.deleteModal}
          />
        </div>
      );
    });
    return (
      <div>
        {this.state.loading && showLoading}
        <TopBar />
        <div>
          <ConfirmationAlert
            alertTitle={this.state.alertTitle}
            alertContent={this.state.alertContent}
            alertAction={this.state.deleteActivity}
            alertFunctionParameter={this.state.alertActivityid}
          />
          {this.state.showNewActivityForm && (
            <NewActivityForm
              saveNewActivity={this.saveNewActivity}
              hideNewActivityForm={this.hideNewActivityForm}
            />
          )}
          <section className="cardList">
            <div id="timeline" />
            {/* <h1>{this.state.tripKey}</h1> */}
            {this.state.noActivityMsg && (
              <CardAlert
                title="No tienes actividades registradas"
                subtitle="¡Comienza ahora!."
              />
            )}
            {!this.state.loading && showCards}
          </section>

          {/* <button
            className="btn-floating btn-large waves-effect waves-light red"
            onClick={this.displayNewActivityForm}
          >
            <i className="material-icons">add</i>
          </button>
          <ScrollUpButton
            ContainerClassName="btn-floating btn-large waves-effect waves-light red botontop"
            TransitionClassName="botontransition"
          /> */}

          <FloatingButton
            // displayNew={this.displayNewActivityForm}
            displayAllActivities={this.displayAllActivities}
            hideAllActivities={this.hideAllActivities}
            showHistoryButtons={true}
          />
        </div>
      </div>
    );
  }
}

export default TripActivities;
