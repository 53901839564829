import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CardNote extends Component {
  render() {
    return (
      <div>
        <article className={'card card-note'}>
          <div className="card-image imgFullWidth waves-effect waves-block waves-light">
            <div className="card-note-tag">{this.props.note.tripname}</div>
            <img
              alt={this.props.note.tripname}
              src={this.props.note.tripimage}
            />
          </div>
          <div className="card-line-top" />
          <div className="card-content bg-card">
            <h4>
              <i className="tiny material-icons">note</i>
              {this.props.note.notetitle}
            </h4>
            <p className="card-description">{this.props.note.notetext}</p>
            {this.props.note.noteurl && (
              <p className="alignButton">
                <a
                  className="waves-effect waves-light btn cyan"
                  href={this.props.note.noteurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="material-icons left">link</i>Enlace
                </a>
              </p>
            )}
          </div>
        </article>
      </div>
    );
  }
}
export default CardNote;
