import React, { Component } from 'react';

class CardAlert extends Component {
  render() {
    return (
      <article className={'card card-alert'}>
        <div className="card-content bg-card">
          <i className="large material-icons">warning</i>
          <h1>{this.props.title}</h1>
          <p>{this.props.subtitle}</p>
        </div>
      </article>
    );
  }
}
export default CardAlert;
