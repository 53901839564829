import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import { modalOptions } from '../../CommonFunctions';

class NewNoteForm extends Component {
  constructor(props) {
    super(props);
    this.cmbTripName = React.createRef();
    this.txtNoteTitle = React.createRef();
    this.txtNoteText = React.createRef();
    this.txtNoteURL = React.createRef();
    this.modalOptions = modalOptions;
  }
  componentDidMount() {
    let elems = document.querySelectorAll('.modal');
    let instancesModal = M.Modal.init(elems, this.modalOptions);
    console.log(instancesModal);
    this.modal = M.Modal.getInstance(document.querySelector('#new'));
  }
  render() {
    let trips = this.props.slugs.map(tripdata => {
      return (
        <option value={tripdata.tripslug} data-icon={tripdata.tripimage}>
          {tripdata.tripname}
        </option>
      );
    });
    let cmbTrips = () => {
      let elems = document.querySelectorAll('select');
      let instanceSelect = M.FormSelect.init(elems);
      console.log(instanceSelect);
      return (
        <select id="cmbTripName" ref={this.cmbTripName}>
          <option value="" disabled selected>
            Elegir viaje
          </option>
          {trips}
        </select>
      );
    };

    return (
      <section
        key="newNoteForm"
        className="newNoteForm modal card grey lighten-4"
        id="new"
      >
        {/* {JSON.stringify(this.props.slugs)} */}
        <div className="">
          <div className="card-content grey-text">
            <span className="card-title">Nueva Nota</span>
            <div className="row">
              <div className="input-field col s12">
                <i className="material-icons prefix">note</i>
                <input
                  id="txtNoteTitle"
                  type="text"
                  className="validate"
                  ref={this.txtNoteTitle}
                />
                <label className="grey-text" htmlFor="txtNoteTitle">
                  Nombre de la nota
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">list</i>
                {cmbTrips()}
                <label className="grey-text" htmlFor="cmbTripName">
                  ¿A qué viaje corresponde esta nota?
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">notes</i>
                <textarea
                  id="txtNoteText"
                  className="materialize-textarea"
                  ref={this.txtNoteText}
                />
                <label className="grey-text" htmlFor="txtNoteText">
                  Contenido de la Nota
                </label>
              </div>
              <div className="input-field col s12">
                <i className="material-icons prefix">link</i>
                <input
                  id="txtNoteURL"
                  type="text"
                  className="validate"
                  ref={this.txtNoteURL}
                />
                <label className="grey-text" htmlFor="txtNoteURL">
                  Enlace (Opcional)
                </label>
              </div>

              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large red modal-close"
                  // onClick={this.props.hideNewNoteForm}
                >
                  <i className="material-icons center">close</i>
                </button>
              </div>
              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large cyan"
                  onClick={() => {
                    if (
                      this.props.saveNewNote(
                        this.cmbTripName.current.value,
                        this.txtNoteTitle.current.value,
                        this.txtNoteText.current.value,
                        this.txtNoteURL.current.value
                      )
                    ) {
                      this.modal.close();
                      this.txtNoteTitle.current.value = '';
                      this.txtNoteText.current.value = '';
                      this.txtNoteURL.current.value = '';
                    }
                    // this.props.hideNewNoteForm();
                  }}
                >
                  <i className="material-icons center">save</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NewNoteForm;
