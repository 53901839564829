import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import {
  datePickerOptions,
  timePickerOptions,
  modalOptions
} from '../../CommonFunctions';
class NewActivityForm extends Component {
  constructor(props) {
    super(props);
    this.txtActivityTitle = React.createRef();
    this.txtStartDate = React.createRef();
    this.txtTime = React.createRef();
    this.cmbActivityType = React.createRef();
    this.txtNotes = React.createRef();
    this.txtImageURL = React.createRef();
    this.txtMapURL = React.createRef();
    this.txtURL = React.createRef();
    this.datePickerOptions = datePickerOptions;
    this.timePickerOptions = timePickerOptions;
    this.modalOptions = modalOptions;
  }
  componentDidMount() {
    let elems = document.querySelectorAll('.datepicker');
    let instanceDate = M.Datepicker.init(elems, this.datePickerOptions);

    elems = document.querySelectorAll('.timepicker');
    let instanceTime = M.Timepicker.init(elems, this.timePickerOptions);

    elems = document.querySelectorAll('select');
    let instanceSelect = M.FormSelect.init(elems);

    elems = document.querySelectorAll('.modal');
    let instancesModal = M.Modal.init(elems, this.modalOptions);

    console.log(instanceDate + instanceTime + instanceSelect + instancesModal);
    this.modal = M.Modal.getInstance(document.querySelector('#new'));
  }
  render() {
    return (
      <section
        key="newActivityForm"
        className="newActivityForm modal card grey lighten-4"
        id="new"
      >
        <div className="">
          <div className="card-content grey-text">
            <span className="card-title">Nueva Actividad</span>
            <div className="row">
              <div className="input-field col s12">
                <i className="material-icons prefix">local_activity</i>
                <input
                  id="txtActivityTitle"
                  type="text"
                  className="validate"
                  ref={this.txtActivityTitle}
                />
                <label className="grey-text" htmlFor="txtActivityTitle">
                  Nombre de la actividad
                </label>
              </div>

              <div className="input-field col s6">
                <i className="material-icons prefix">date_range</i>
                <input
                  id="txtStartDate"
                  type="text"
                  className="datepicker"
                  ref={this.txtStartDate}
                />
                <label className="grey-text" htmlFor="txtStartDate">
                  Fecha
                </label>
              </div>

              <div className="input-field col s6">
                <i className="material-icons prefix">access_time</i>
                <input
                  id="txtTime"
                  type="text"
                  className="timepicker"
                  ref={this.txtTime}
                />
                <label className="grey-text" htmlFor="txtTime">
                  Hora
                </label>
              </div>

              <div className="input-field col s12">
                <i className="material-icons prefix">list</i>
                <select id="cmbActivityType" ref={this.cmbActivityType}>
                  <option value="" disabled selected>
                    Elegir tipo
                  </option>
                  <option value="1">Vuelo</option>
                  <option value="2">Tareas</option>
                  <option value="3">Hospedaje</option>
                  <option value="4">Paseo / Visita</option>
                  <option value="5">Traslado</option>
                </select>
                <label className="grey-text" htmlFor="cmbActivityType">
                  Tipo de Actividad
                </label>
              </div>

              <div className="input-field col s12">
                <i className="material-icons prefix">notes</i>
                <textarea
                  id="txtNotes"
                  className="materialize-textarea"
                  ref={this.txtNotes}
                />
                <label className="grey-text" htmlFor="txtNotes">
                  Notas
                </label>
              </div>

              <div className="input-field col s6">
                <i className="material-icons prefix">image</i>
                <input
                  id="txtImageURL"
                  type="text"
                  className="validate"
                  ref={this.txtImageURL}
                />
                <label className="grey-text" htmlFor="txtImageURL">
                  URL de la imagen
                </label>
              </div>

              <div className="input-field col s6">
                <i className="material-icons prefix">map</i>
                <input
                  id="txtMapURL"
                  type="text"
                  className="validate"
                  ref={this.txtMapURL}
                />
                <label className="grey-text" htmlFor="txtMapURL">
                  URL de ubicación
                </label>
              </div>

              <div className="input-field col s12">
                <i className="material-icons prefix">map</i>
                <input
                  id="txtURL"
                  type="text"
                  className="validate"
                  ref={this.txtURL}
                />
                <label className="grey-text" htmlFor="txtURL">
                  URL de actividad
                </label>
              </div>

              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large red modal-close"
                  //onClick={this.props.hideNewActivityForm}
                >
                  <i className="material-icons center">close</i>
                </button>
              </div>
              <div className="input-field col s6 center">
                <button
                  className="waves-effect waves-light btn-large cyan"
                  onClick={() => {
                    if (
                      this.props.saveNewActivity(
                        this.txtActivityTitle.current.value,
                        this.txtStartDate.current.value,
                        this.txtTime.current.value,
                        this.cmbActivityType.current.value,
                        this.txtNotes.current.value,
                        this.txtImageURL.current.value,
                        this.txtMapURL.current.value,
                        this.txtURL.current.value
                      )
                    ) {
                      this.modal.close();
                      this.txtActivityTitle.current.value = '';
                      this.txtStartDate.current.value = '';
                      this.txtTime.current.value = '';
                      this.txtURL.current.value = '';
                      this.txtNotes.current.value = '';
                      this.txtImageURL.current.value = '';
                      this.txtMapURL.current.value = '';
                    }
                    //this.props.hideNewActivityForm();
                  }}
                >
                  <i className="material-icons center">save</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NewActivityForm;
