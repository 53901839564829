import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';

class FloatingButton extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Scroll to Top
    this.state = {
      showFilterButtons: false
    };
  }
  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
    let elems = document.querySelectorAll('.fixed-action-btn');
    let instance = M.FloatingActionButton.init(elems, {
      direction: 'left',
      hoverEnabled: false
    });
    if (this.props.showHistoryButtons)
      this.setState({ showFilterButtons: true });
    console.log(instance);
  }
  render() {
    this.historyButtons = this.state.showFilterButtons ? (
      <li>
        <a
          href="#showPastActivities"
          className="btn-floating orange waves-effect waves-light btn modal-trigger"
          onClick={() => {
            this.props.displayAllActivities();
            this.setState({ showFilterButtons: false });
          }}
        >
          <i className="material-icons">history</i>
        </a>
      </li>
    ) : (
      <li>
        <a
          href="#hidePastActivities"
          className="btn-floating orange waves-effect waves-light btn modal-trigger"
          onClick={() => {
            this.props.hideAllActivities();
            this.setState({ showFilterButtons: true });
          }}
        >
          <i className="material-icons">today</i>
        </a>
      </li>
    );

    return (
      <div className="fixed-action-btn">
        <button className="btn-floating btn-large red">
          <i className="large material-icons">add</i>
        </button>
        <ul>
          <li>
            <a href="#" className="btn-floating pink" ref={this.myRef}>
              <i className="material-icons">arrow_upward</i>
            </a>
          </li>
          <li>
            <a
              href="#new"
              className="btn-floating blue waves-effect waves-light btn modal-trigger"
            >
              <i className="material-icons">note_add</i>
            </a>
          </li>
          {this.props.showHistoryButtons && this.historyButtons}
        </ul>
      </div>
    );
  }
}
export default FloatingButton;
