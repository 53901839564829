import React, { Component } from 'react';

class CardActivity extends Component {
  render() {
    return (
      <div>
        <article className={'card card-' + this.props.activity.activitytype}>
          {this.props.activity.activitytype !== '2' && (
            <div className="card-image imgFullWidth waves-effect waves-block waves-light">
              <img
                alt={this.props.activity.activitytitle}
                src={this.props.activity.activityimage}
              />
            </div>
          )}
          <div
            className={
              'card-line-top card-line-bg-' + this.props.activity.activitytype
            }
          />
          <div className="card-content bg-card">
            {this.props.activity.activitytype !== '2' && (
              <button
                id="readMore"
                className="btn-floating btn-large waves-effect waves-light red activator card-line-bg-red"
              >
                <i className="material-icons">more_horiz</i>
              </button>
            )}

            <div className="card-date-holder">
              <span className="card-date">
                {this.props.activity.activitydatetext}
              </span>
              <span className="card-date">
                {this.props.convert12hrs(this.props.activity.activityhora)}
              </span>
            </div>
            <h2 className="card-title grey-text text-darken-4">
              {this.props.activity.activitytitle}
            </h2>
            {this.props.activity.activitytype === '4' ? (
              <p className="card-description">
                {this.props.activity.activitynotes.substring(0, 200)}{' '}
                <sub>[+]</sub>
              </p>
            ) : (
              <p className="card-description">
                {this.props.activity.activitynotes}
              </p>
            )}
          </div>
          {this.props.activity.activitytype !== '2' && (
            <div className="card-reveal">
              <span className="card-title grey-text text-darken-4 close-holder">
                <i
                  className="waves-effect waves-light material-icons"
                  onClick={() => {
                    this.props.deleteModal(
                      this.props.activity.activitytitle,
                      this.props.activity.activityid
                    );
                  }}
                >
                  delete
                </i>
                <i className="waves-effect waves-light material-icons">edit</i>

                <i className="waves-effect waves-light material-icons pulse">
                  expand_more
                </i>
              </span>

              <div className="card-info-holders">
                <a
                  href={this.props.activity.activitylocation}
                  className="card-info icomaps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver ubicación en <strong>Google Maps©</strong>
                </a>
                <a
                  href={this.props.activity.activityurl}
                  className="card-info icolink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mas información <strong>sobre actividad</strong>
                </a>
              </div>
              <p>
                <strong>{this.props.activity.activitytitle}</strong>
                <br />
                {this.props.activity.activitynotes}
              </p>
            </div>
          )}
        </article>
      </div>
    );
  }
}

export default CardActivity;
