import React from 'react';
import '../styles/Page404.css';

export default class Page404 extends React.Component {
  render() {
    return (
      <div key="page404" className="page404">
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/30eb399e-f568-4557-a82b-61b3f2e78232/d7kj0os-7fccf478-7220-4c8a-b43d-a62f9c1bb2e7.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzMwZWIzOTllLWY1NjgtNDU1Ny1hODJiLTYxYjNmMmU3ODIzMlwvZDdrajBvcy03ZmNjZjQ3OC03MjIwLTRjOGEtYjQzZC1hNjJmOWMxYmIyZTcuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.ukyqTIW4vX5xJZMg0nCe1FfGCGNfdf1I9P5ftVUVSN0"
          alt="404"
        />
        <h2>Error 404: Lo que buscas no se encuentra aquí</h2>
        <a href="/" className="waves-effect waves-teal btn-flat">
          {' '}
          <i className="material-icons left">arrow_back</i> Travify
        </a>
      </div>
    );
  }
}
