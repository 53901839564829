// npm install --save re-base firebase
// https://github.com/tylermcginnis/re-base
// https://console.firebase.google.com/u/0/project/travify-6f162/settings/general/?hl=es-419

import Rebase from 're-base';
import firebase from 'firebase/app';
require('firebase/firestore');

export let app = firebase.initializeApp({
  // apiKey: 'AIzaSyAWEjriPVwRtx0MdADaDQJr_tKuqfMbaCc',
  // authDomain: 'travify-6f162.firebaseapp.com',
  // databaseURL: 'https://travify-6f162.firebaseio.com/',
  // storageBucket: 'travify-6f162.appspot.com',
  // projectId: 'travify-6f162',
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_PROJECT_ID
});

let firestore = app.firestore();
// let settings = { timestampsInSnapshots: true };
let settings = {};
firestore.settings(settings);

let base = Rebase.createClass(firestore);

export default base;
