import React, { Component } from 'react';
import base from '../components/rebase';
import { convertToSlug, imageVerification } from '../CommonFunctions';
//import { withRouter } from 'react-router-dom';
// import ScrollUpButton from 'react-scroll-up-button';

import Loading from '../components/UI/Loading';
import TopBar from '../components/UI/TopBar';
import FloatingButton from '../components/UI/FloatingButton';

import CardNote from '../components/Cards/CardNote';
import CardAlert from '../components/Cards/CardAlert';
import NewNoteForm from '../components/Forms/NewNoteForm';
import M from 'materialize-css/dist/js/materialize.min.js';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.convertToSlug = convertToSlug;
    this.imageVerification = imageVerification;

    this.loadNotes = () => {
      this.setState({ loading: true });
      base
        .get('trips', {
          context: this,
          //query: (ref)=> ref.where('owner','==',localStorage.getItem("email")),
          query: ref => {
            return (
              ref
                //.where('owner', '==', localStorage.getItem('email'))
                .where(
                  'travellers',
                  'array-contains',
                  localStorage.getItem('email')
                )
            );
          }
        })
        .then(data => {
          let slugs = [];
          data.map(trip => {
            return slugs.push({
              tripslug: trip.slug,
              tripimage: trip.imageURL,
              tripname: trip.tripName
            });
          });
          let newState = { ...this.state };
          newState.slugs = slugs;
          this.setState(newState);
          //console.log("Slugs: "+JSON.stringify(slugs));
          //Iniciar carga de notas
          this.setState({ notes: [] });
          slugs.map(trip => {
            return base
              .get('trips/' + trip.tripslug + '/notes', {
                context: this,
                query: ref => {
                  return ref.orderBy('notedatetime');
                }
              })
              .then(data => {
                //console.log('Durante el ciclo: ' + JSON.stringify(data));
                let newState = { ...this.state };
                for (const val in data) {
                  newState.notes.push({
                    notetitle: data[val].notetitle,
                    notetext: data[val].notetext,
                    notedatetime: data[val].notedatetime,
                    noteurl: data[val].noteurl,
                    noteslug: data[val].noteslug,
                    tripname: trip.tripname,
                    tripimage: this.imageVerification(trip.tripimage),
                    tripslug: trip.tripslug
                  });
                }
                // console.log("Valor de newState antes de meter en setState: "+JSON.stringify(newState));
                newState.loading = false;
                newState.noTripMsg = false;
                newState.noNotes = false;
                this.setState(newState);
                // console.log(
                //   'Contenido final de state: ' + JSON.stringify(this.state)
                // );
              })
              .catch(err => {
                this.setState({
                  loading: false,
                  noNotes: true
                });
              });
          });
        })
        .catch(err => {
          console.log('Error regresado: ' + err);
          this.setState({
            loading: false,
            noTripMsg: true
          });
        });
    };

    // this.displayNewNoteForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewNoteForm = true;
    //   this.setState(newState);
    // };
    // this.hideNewNoteForm = () => {
    //   // alert("Creando nuevo viaje");
    //   let newState = { ...this.state };
    //   newState.showNewNoteForm = false;
    //   this.setState(newState);
    // };
    this.saveNewNote = (tripSlug, noteTitle, noteText, noteURL) => {
      if (!tripSlug || !noteTitle || !noteText) {
        alert('Por favor proporcione todos los datos solicitados');
        return false;
      }
      console.log(tripSlug + ' ' + noteTitle + ' ' + noteText + ' ' + noteURL);
      let today = new Date();
      let date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      let newNote = base
        .addToCollection(
          'trips/' + tripSlug + '/notes',
          {
            notetitle: noteTitle,
            notedatetime: date,
            notetext: noteText,
            noteurl: noteURL,
            noteslug: this.convertToSlug(date) + this.convertToSlug(noteTitle)
          },
          this.convertToSlug(date) + this.convertToSlug(noteTitle)
        )
        .then(() => {})
        .catch(err => {});
      //available immediately, you don't have to wait for the callback to be called
      let generatedKey = newNote.key;
      if (generatedKey !== '') this.loadNotes();
      // alert('newNote: ' + JSON.stringify(newNote));
      this.setState({ noTripMsg: false });
      return true;
    };
    this.state = {
      notes: [],
      slugs: [],
      showNewNoteForm: true,
      loading: false,
      noTripMsg: false,
      noNotes: false
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    let elems = document.querySelectorAll('.fixed-action-btn');
    let instance = M.FloatingActionButton.init(elems, {
      direction: 'left'
    });
    console.log(instance);
    this.loadNotes();
  }

  render() {
    let showLoading = <Loading />;
    let showCards = this.state.notes.map(note => {
      return (
        <div key={'cont' + note.noteslug}>
          <CardNote note={note} key={'note-' + note.noteslug} />
        </div>
      );
    });
    return (
      <div>
        {this.state.loading && showLoading}
        <TopBar />
        <div>
          {this.state.showNewNoteForm && (
            <NewNoteForm
              saveNewNote={this.saveNewNote}
              hideNewNoteForm={this.hideNewNoteForm}
              slugs={this.state.slugs}
            />
          )}
          <section className="cardList">
            <div id="timeline" />
            <div className="SectionTitle"> Notas </div>
            {this.state.noTripMsg && (
              <CardAlert
                title="Aún no tienes viajes registrados"
                subtitle="Para crear notas, primero debes tener viajes registrados"
              />
            )}
            {this.state.noNotes && (
              <CardAlert
                title="Aún no tienes notas registradas"
                subtitle="Comienza en este momento"
              />
            )}
            {!this.state.loading && showCards}
          </section>

          {!this.state.noTripMsg && <FloatingButton />}
        </div>
      </div>
    );
  }
}
export default Notes;
